.port__contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0 10rem 0;
}

.port__contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.port__contact-container p {
  font-family: var(--font-manrope);
  max-width: 90vw;
  text-align: center;
  padding: 2rem 0;
  color: var(--slate);
}

.port__contact-container button {
  color: var(--green);
  background-color: transparent;
  border: 1px solid var(--green);
  border-radius: var(--border-radius);
  cursor: pointer;
  padding: 1.25rem 1.75rem;
  font-family: var(--font-mono);
  cursor: pointer;
  font-size: var(--fz-sm);
  line-height: 1;
}

@media only screen and (min-width: 600px) {
  .port__contact-container p {
    max-width: 500px;
  }
}
