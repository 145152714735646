.port__projectCard {
  width: 100%;
  margin-top: 3rem;
}
.port__projectCard-darkbg {
  background: var(--color-sec);
  width: 100%;
}

.port__projectCard-a {
  width: 100%;
}
.port__projectCard-btn {
  width: 100%;
}

@media only screen and (min-width: 600px) {
  .port__projectCard {
    max-width: 400px;
    margin-right: 3rem;
  }
  /* .port__projectCard-darkbg {
    min-height: 400px;
  } */
}
