.port__header-container {
  max-width: 90vw;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

/* .port__header-container {
  padding: 0 8rem;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
} */

.port__header-container_1 h1 {
  color: var(--green);
  font-family: var(--font-mono);
  font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
  margin: 0px 0px 30px 4px;
}

.port__header-container_2 h2 {
  color: var(--lightest-slate);
  font-family: var(--font-manrope);
  font-weight: 600;
  line-height: 1.1;
  font-size: clamp(40px, 8vw, 80px);
  margin: 0;
}

.port__header-container_3 h3 {
  margin-top: 10px;
  font-family: var(--font-manrope);
  color: var(--slate);
  line-height: 0.9;
  font-size: clamp(40px, 8vw, 80px);
  font-weight: 600;
}

.port__header-container_4 p {
  margin: 20px 0px 0px;
  max-width: 540px;
  color: var(--slate);
  font-size: var(--fz-md);
  font-family: var(--font-manrope);
  line-height: 2;
}

.port__header-container_button button {
  color: var(--green);
  background-color: transparent;
  border: 1px solid var(--green);
  border-radius: var(--border-radius);
  cursor: pointer;
  padding: 1.25rem 1.75rem;
  font-family: var(--font-mono);
  cursor: pointer;
  margin-top: 40px;
  font-size: var(--fz-sm);
  line-height: 1;
}
