.port__navbar {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.port__navbar-logo h1 {
  color: var(--green);
  font-family: var(--font-manrope);
}

.port__navbar-bigScreen {
  display: none;
}
.port__navbar-smallscreen {
  display: flex;
}
.app__navbar-smallscreen_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--color-bg);
  transition: 0.5s ease;
  flex-direction: column;
  z-index: 5;

  display: flex;
  justify-content: center;
  align-items: center;
}

.app__navbar-smallscreen-close {
  color: white;
  cursor: pointer;
  margin-bottom: 1rem;
}

.port__navbar-container {
  display: flex;
  flex-direction: column;
}

.port__navbar-container button {
  color: var(--green);
  background-color: transparent;
  border: 1px solid var(--green);
  border-radius: var(--border-radius);
  cursor: pointer;
  padding: 1rem 1rem;
  font-family: var(--font-mono);
  cursor: pointer;
  margin-left: 15px;
  font-size: var(--fz-xs);
  line-height: 0.5;
}

.port__navbar-container_links {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.port__navbar-container_links p {
  font-size: 13px;
  font-family: var(--font-mono);
}

.port__navbar-container_links p:first-child {
  color: var(--green);
  margin-right: 5px;
}

.port__navbar-container_links p:last-child {
  color: var(--lightest-slate);
  margin-right: 30px;
}

@media only screen and (min-width: 600px) {
  .port__navbar-smallscreen {
    display: none;
  }
  .port__navbar-bigScreen {
    display: flex;
  }
  .port__navbar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .port__navbar-container_links {
    margin-bottom: 0;
  }
}

/* .port__navbar-bigScreen {
  display: flex;
  justify-content: space-between;
  padding: 2rem 2rem;
} */
/* 




.app__navbar-smallscreen {
  display: flex;
}

 */
