.port__projects {
  max-width: 90vw;
  min-height: 90vh;
  margin: 10rem auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* .port__projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 8rem 8rem 8rem;
  min-height: 90vh;
} */

.port__projects-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

/* @media screen and (max-width: 1050px) {
  .port__projects {
    padding: 5rem 10rem 15rem 5rem;
  }
} */
