.port__about {
  max-width: 90vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.port__about-container {
  margin-top: 2rem;
  background-color: var(--color-sec);
  padding: 30px;
  border-radius: 5px;
}

/* .port__about {
  display: flex;
  padding: 0 8rem 8rem 8rem;
  align-items: center;
  justify-content: center;
} */

/* .port__about-container {
  margin-right: 3rem;
  flex: 1;
} */

/* .port__about-profilePic {
  flex: 0.5;
} */

.port__about-container_heading h2:first-child {
  font-family: var(--font-mono);
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  color: var(--green);
  padding-right: 1rem;
}

.port__about-container_heading h2:last-child {
  font-family: var(--font-manrope);
  font-size: 30px;
  font-weight: 600;
  line-height: 26px;
  color: var(--lightest-slate);
}

.port__about-container_content p {
  font-family: var(--font-manrope);
  color: var(--slate);
  line-height: 2;
  font-size: var(--fz-md);
  margin: 1rem 0 0 0;
}
.port__about-profilePic {
  max-width: 500px;
}

.port__about-profilePic img {
  width: 100%;
}

@media only screen and (min-width: 600px) {
  .port__about-container {
    display: flex;
    align-items: center;
  }
  .port__about-container_content {
    margin-right: 2rem;
    max-width: 600px;
  }
}
