.port__heading {
  display: flex;
}

.port__heading h2:first-child {
  font-family: var(--font-mono);
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  color: var(--green);
  padding-right: 1rem;
}

.port__heading h2:last-child {
  font-family: var(--font-manrope);
  font-size: 30px;
  font-weight: 600;
  line-height: 26px;
  color: var(--lightest-slate);
}
