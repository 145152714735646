.port__footer {
  margin: 50px 0 10px 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.port__footer p {
  font-family: var(--font-mono);
  color: var(--slate);
  font-size: 12px;
}
